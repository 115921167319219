import type { HeadersFunction, LoaderFunction, MetaFunction } from '@remix-run/node'

import { json, redirect } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { addAdUnit } from '@sporza/design-system/molecules/advertisement'
import { useTrackingStore } from '@sporza/tracking'
import { isSamePath } from '@sporza/utils/urls'
import { useEffect } from 'react'

import { createPageTitle, getSeoMeta, toVersion2 } from '~/features/seo'
import { getEtag, setHeaders, withErrorHandling } from '~/helpers'
import { DefaultLayout } from '~/layouts'
import bffClient from '~/services/bff'

export const headers: HeadersFunction = (
  {
    loaderHeaders,
    parentHeaders
  }
) => {
  const combinedHeaders = loaderHeaders

  if (loaderHeaders.get('etag') && parentHeaders.get('etag')) {
    combinedHeaders.set('etag', getEtag(
      {
        parentEtag: parentHeaders.get('etag'),
        loaderEtag: loaderHeaders.get('etag')
      }
    ))
  }

  return combinedHeaders
}

export const loader: LoaderFunction = withErrorHandling(async ({ request }) => {
  const requestUrl = new URL(request.url)
  const page = await bffClient.getInternalPage(requestUrl.pathname, 'aem', undefined, true)

  if (!page)
    throw new Response('Not Found', { status: 404 })

  if (page?.permalink && !isSamePath(page.permalink, requestUrl)) {
    const permalink = new URL(page.permalink)

    if (requestUrl.hash) {
      permalink.hash = requestUrl.hash
    }

    return redirect(permalink.toString(), 301)
  }

  const headers = setHeaders(page)

  return json({
    page,
    designSystemBaseUrl: process.env.DESIGN_SYSTEM_BASE_URL,
    apiBaseUrl: process.env.API_BASE_URL
  }, { headers })
})

export const meta: MetaFunction = ({ data }) => {
  const { page } = data
  const {
    title,
    seoTitle,
    description,
    structuredData,
    seoImage: image,
    seoPublicationDate: publicationDate,
    permalink
  } = page || {}

  const seoMeta = getSeoMeta({
    title: createPageTitle(seoTitle || title),
    description
  })

  return toVersion2({
    ...seoMeta,
    image,
    publicationDate,
    permalink
  }, structuredData)
}

export default function Slug() {
  const data = useLoaderData<any>()
  const { page, designSystemBaseUrl, apiBaseUrl, bffBaseUrl } = data
  const {
    digitalData,
    ebaData,
    adData
  } = page

  const initTracking = useTrackingStore((state) => state.initTracking)

  useEffect(() => {
    initTracking({ digitalData, ebaData })
    addAdUnit(adData)
  })

  return <DefaultLayout
    accessibilityTitle={'hoofdpunten'}
    accessibilitySubTitle={'belangrijkste sportnieuws'}
    designSystemBaseUrl={designSystemBaseUrl}
    apiBaseUrl={apiBaseUrl}
    bffBaseUrl={bffBaseUrl}
    {...page}
  />
}
